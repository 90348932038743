import vue from 'vue';
import { Message, Notification, Loading } from 'ElementUI';
import { getNowTimetp } from '@/utils/index.js';
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
let loadingInstance;

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
    //  if (config.method == 'get' || config.method == 'delete' ) {
    //   if(config.url.indexOf("?") > -1){
    //       config.url += '&tp=' + getNowTimetp();
    //   }else{
    //       config.url += '?&tp=' + getNowTimetp();
    //   }
    // } else {
    //   //console.log((params));
    //   //if(typeof(params)=='string'){
    //   try {
    //       config.data.tp = getNowTimetp();
    //   } catch (e) {
    //       config.data = eval('(' + config.data + ')');
    //       config.data.tp = getNowTimetp();
    //       config.data = JSON.stringify(config.data);
    //   }
    // }

    // 全局Loading配置
    if (config.headers['X-Custom-Header'] && config.headers['X-Custom-Header'] == 'Loading') {
      loadingInstance = Loading.service({
        // target: document.querySelector('.edit_part'),
        lock: true,
        background: 'rgba(0, 0, 0, 0.3)'
      });
      delete config.headers['X-Custom-Header'];
    }
    
    return config;
  },
  error => {
    loadingInstance && loadingInstance.close();
  }
);

http.interceptors.response.use(
  response => {
    switch (response.data.code) {
      case "0":
        break;
      case "-5":
        break;
      case "1":
        break;
      default:
        Message.error({ title: "请求错误", message: response.data.message });
        break;
    }
    loadingInstance && loadingInstance.close();
    return response.data;
  },
  error => {
    console.log('请求响应错误：', error.config, error.code);
    loadingInstance && loadingInstance.close();
    if (error.message == 'Network Error' || error.message.indexOf('timeout') != -1) {
      var config = error.config;

      // 设置变量以跟踪重试计数
      config.__retryCount = config.__retryCount || 0;

      // 检查是否已经刷爆重试的总数
      if (config.__retryCount >= config.retry) {
        return Promise.reject(error);
      }
      
      // 增加重试计数
      config.__retryCount += 1;
      
      // 创建新的promise以处理指数退避
      var backoff = new Promise(function(resolve) {
        setTimeout(function() {
          resolve();
        }, config.retryDelay || 1);
      });
      
      // 返回在其中调用axios来重试请求
      return backoff.then(function() {
        return axios(config);
      });
    } else {
      return Promise.reject(error);
    }
    
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          Notification({
            title: '提示',
            message: '登录已失效，请刷新后重试',
            type: 'error',
            duration: 0
          });
          break;
        default:
      //  case 400:
      //       showErrorMessage('请求出错');
      //       break;
      //   case 403:
      //       showErrorMessage('拒绝访问');
      //       break;
      //   case 404:
      //       showErrorMessage('资源不存在');
      //       break;
      //   case 405:
      //       showErrorMessage('请求方法未允许');
      //       break;
      //   case 500:
      //       showErrorMessage('服务器内部出错');
      //       break;
      //   case 503:
      //       showErrorMessage('访问服务器失败');
      //       break;
      //   default:
      //     showErrorMessage(message);
      }
    }
    return Promise.reject(error);
  }
);

export default http;
