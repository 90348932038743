<template>
  <!--<section
    class="app-main"
    v-loading="!visible"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >-->
  <section class="app-main">
    <router-view></router-view>
    <homePage v-if="this.$route.path=='/homePage'"></homePage>
  </section>
</template>


<script>
import Bus from "@/utils/bus";
import homePage from "@/pages/home/homePage.vue";

//import materialboard from "@/pages/materialboard/Index" //备货总量看板


//import { getCurrentEmergency } from "@/api/module/emergency";
export default {
  name: "AppMain",
  data() {
    return {
      visible: false,
      timer: null,
      typeid:''
    };
  },
  components:{
    homePage
  },
  computed: {
    companyId() {
      return this.$store.state.company.id;
    }
  },
  created(){},
  mounted() {
     this.$router.push({path:'/homePage'})
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    
  }
};
</script>
<style lang="scss" scoped>
.app-main {
  padding: 10px 10px;
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  min-width: 1060px;
  height:95%;
  margin-top: 60px;
}
</style>
