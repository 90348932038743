var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-container" },
    [
      _c("el-scrollbar", { staticClass: "scrollbar-wrapper" }, [
        _c(
          "div",
          { staticClass: "wrap", attrs: { id: "wrap" } },
          [
            _c("div", { staticClass: "logo-container" }, [
              _c("a", { attrs: { href: "#/" } }, [
                this.isCollapse == false
                  ? _c("h3", { staticClass: "inline ml10 mt20" }, [
                      _c("img", {
                        attrs: { src: _vm.logoSrc, alt: _vm.altText }
                      })
                    ])
                  : _vm._e(),
                this.isCollapse == true
                  ? _c("h3", { staticClass: "inline ml10 mt20 mr20" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/image/logosimple.png")
                        }
                      })
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "el-menu",
              {
                staticClass: "elmenuclass",
                attrs: {
                  "default-active": "0",
                  "background-color": "#004980",
                  "text-color": "#fff",
                  "active-text-color": "#409eff",
                  router: ""
                },
                on: { open: _vm.handleOpen, close: _vm.handleClose }
              },
              [
                _c(
                  "el-menu-item",
                  { attrs: { index: "/homePage" } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "home",
                        "class-name": "menu-icon mr10"
                      }
                    }),
                    this.isCollapse == false
                      ? _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("首页")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-menu",
              {
                staticClass: "el-menu-vertical-demo",
                staticStyle: { height: "100%" },
                attrs: {
                  "default-active": _vm.$route.path,
                  "background-color": "#004980",
                  "text-color": "#fff",
                  collapse: _vm.isCollapse,
                  "active-text-color": "#409eff",
                  "unique-opened": _vm.uniqueOpened,
                  router: ""
                },
                on: { open: _vm.handleOpen, close: _vm.handleClose }
              },
              _vm._l(_vm.menus, function(menu, index) {
                return _c(
                  "el-submenu",
                  { key: index, attrs: { index: index + "" } },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": _vm.getIcon(menu.icon),
                            "class-name": "menu-icon mr10"
                          }
                        }),
                        _c("span", [_vm._v(_vm._s(menu.name))])
                      ],
                      1
                    ),
                    _vm._l(menu.subMenus, function(subMenu, index2) {
                      return _c(
                        "el-menu-item-group",
                        { key: index2 },
                        [
                          _c(
                            "el-menu-item",
                            { attrs: { index: subMenu.path } },
                            [_c("span", [_vm._v(_vm._s(subMenu.name))])]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              1
            ),
            _c("LocalFile")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }