var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("side-bar"),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { collapse: this.isCollapse == true }
        },
        [_c("nav-bar"), _c("app-main")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }