var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { navbar: _vm.styleObject, navbars: _vm.hasError } },
    [
      _c("i", {
        staticClass: "el-icon-s-grid",
        staticStyle: {
          float: "left",
          "margin-left": "20px",
          "font-size": "20px",
          "margin-top": "20px",
          color: "#409EFF"
        },
        on: { click: _vm.clickifshow }
      }),
      _c(
        "div",
        { staticClass: "right-menu mr15" },
        [
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click" },
              on: { command: _vm.departmentChange }
            },
            [
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.currentDepartmentName) +
                    "\n        "
                ),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.departments, function(item) {
                  return _c(
                    "el-dropdown-item",
                    { key: item.id, attrs: { command: item } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { trigger: "click" }
            },
            [
              _c("span", [
                _c("i", { staticClass: "el-icon-s-custom" }),
                _vm._v("\n        " + _vm._s(_vm.userRealName) + "\n        "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user",
                  attrs: { slot: "dropdown", width: "auto" },
                  slot: "dropdown"
                },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.$keycloak.accountManagement }
                      },
                      [_vm._v("个人信息管理")]
                    )
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.$keycloak.logoutFn }
                      },
                      [_vm._v("退出")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }