import vue from 'vue';
import ElementUI from 'ElementUI';
import axios from 'axios';
import JsonViewer from 'vue-json-viewer';
import "babel-polyfill";
// import { mapKeys } from 'lodash';
import mapKeys from 'lodash/mapKeys';
import moment from 'moment';
import { UTable } from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
import expriesCache from './utils/apiCache.js';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import './icons/index.js';  // icon
import 'normalize.css/normalize.css';
import plTable from 'pl-table'
import Pagination from '@/components/Pagination';
import 'pl-table/themes/index.css' // 引入样式（必须引入)，vuecli3.0不需要配置，cli2.0请查看webpack是否配置了url-loader对woff，ttf文件的引用,不配置会报错哦

// import 'pl-table/themes/plTableStyle.css' // 默认表格样式很丑 引入这个样式就可以好看啦（如果你不喜欢这个样式，就不要引入，不引入就跟ele表格样式一样）
import "./styles/index.scss";
import "./styles/public.scss";
import "./styles/table.scss";
import "./styles/font.scss";
import "./styles/detail.scss";
import "./styles/dialog.scss";

import store from "./store/store";
import App from "../App.vue";
import router from "./router/index.js";

import './assets/js/directives.js';

import { DataTablesServer } from "vue-data-tables";
// 提前加载菜单
import { getMenus } from "@/api/module/menu.js";
import Bus from "@/bus/eventBus.js";

import preventReClick from './utils/preventReClick.js';  // 防多次点击，重复提交

// import文件判断
async function importHandle() {
  // const vmbStyle = process.env.VUE_APP_TYPE == 'vitality' ? await import('./styles/public_vmb.scss') : '';
  const vmbStyle = process.env.VUE_APP_TYPE == 'vmb' ? await import('./styles/public_vmb.scss') : '';
}
importHandle();

async function getAllMenus(keycloak) {
  let data = {system: "scm", roles: keycloak.resourceAccess.scm.roles};
  let res = await getMenus(data.system, data.roles);
  Vue.prototype.$keycloak.menus = res.data;
  Bus.$emit("menusReady");
};

// 定义全局的请求次数，请求间隙（响应失败处理）
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;

Vue.prototype.expriesCache = expriesCache;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
moment.locale('zh-cn');  // 汉化
vue.use(ElementUI);
Vue.use(JsonViewer);
vue.use(DataTablesServer);
vue.component('Pagination',Pagination);
vue.use(plTable);
vue.use(UTable)
vue.config.productionTip = false;

vue.use(VueKeycloakJs, {
  init: {
    onLoad: "login-required",
    checkLoginIframe: false
  },
  config: {
    url: process.env.VUE_APP_SSO_URL,
    realm: process.env.VUE_APP_REALM,
    clientId: process.env.VUE_APP_CLIENTID
  },
  onReady: keycloak => {
    getAllMenus(keycloak);
    new vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});
