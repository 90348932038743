var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "homePage" }, [
      _c("div", { staticClass: "homecontent" }, [
        _c("ul", { staticClass: "nav" }, [
          _c("li", { staticStyle: { background: "#428fc5" } }, [
            _c("div", { staticClass: "home_manager" }, [
              _c("img", {
                attrs: { src: require("../../assets/image/plan_manager.png") }
              }),
              _c("span", [_vm._v("计划管理")])
            ])
          ]),
          _c("li", { staticStyle: { background: "#5da4da" } }, [
            _c("div", { staticClass: "home_manager" }, [
              _c("img", {
                attrs: { src: require("../../assets/image/board2.png") }
              }),
              _c("span", [_vm._v("看板管理")])
            ])
          ]),
          _c("li", { staticStyle: { background: "#7cbaeb" } }, [
            _c("div", { staticClass: "home_manager" }, [
              _c("img", {
                attrs: { src: require("../../assets/image/flow2.png") }
              }),
              _c("span", [_vm._v("决策管理")])
            ])
          ]),
          _c("li", { staticStyle: { background: "#99cbee" } }, [
            _c("div", { staticClass: "home_manager" }, [
              _c("img", {
                attrs: { src: require("../../assets/image/rectangle13.png") }
              }),
              _c("span", [_vm._v("添加应用")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }