var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ContentMain" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            },
            model: {
              value: _vm.value1,
              callback: function($$v) {
                _vm.value1 = $$v
              },
              expression: "value1"
            }
          }),
          _c(
            "div",
            { staticClass: "input", staticStyle: { "margin-left": "10px" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入公司编号" },
                model: {
                  value: _vm.companycode,
                  callback: function($$v) {
                    _vm.companycode = $$v
                  },
                  expression: "companycode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入公司名称" },
                model: {
                  value: _vm.companyname,
                  callback: function($$v) {
                    _vm.companyname = $$v
                  },
                  expression: "companyname"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "type" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择状态" },
                  model: {
                    value: _vm.typeid,
                    callback: function($$v) {
                      _vm.typeid = $$v
                    },
                    expression: "typeid"
                  }
                },
                _vm._l(_vm.typeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.typename, value: item.typeid }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search_two" },
        [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入产品码" },
                model: {
                  value: _vm.productcode,
                  callback: function($$v) {
                    _vm.productcode = $$v
                  },
                  expression: "productcode"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.searchclick()
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "div",
            { staticClass: "down" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.exportdataexl }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            "element-loading-text": "拼命加载中",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(88, 85, 85, 0.8)",
            data: _vm.tableData
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left", inline: "" }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "公司名称：" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.cardName))])
                        ]),
                        _c("el-form-item", { attrs: { label: "公司编号：" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.cardCode))])
                        ]),
                        _c("el-form-item", { attrs: { label: "规格：" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.itemName))])
                        ]),
                        _c("el-form-item", { attrs: { label: "产品码：" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.codebars))])
                        ]),
                        _c("el-form-item", { attrs: { label: "数量:" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.quantity))])
                        ]),
                        _c("el-form-item", { attrs: { label: "下单日期：" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.docdate))])
                        ]),
                        _c("el-form-item", { attrs: { label: "地址：" } }, [
                          _c("span", [
                            _vm._v(_vm._s(props.row.deliveryAddress))
                          ])
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "公司名称", prop: "cardName" }
          }),
          _c("el-table-column", {
            attrs: { label: "公司编号", prop: "cardCode" }
          }),
          _c("el-table-column", { attrs: { label: "规格", prop: "itemName" } }),
          _c("el-table-column", {
            attrs: { prop: "codebars", label: "产品码", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "quantity", label: "数量", width: "150" }
          })
        ],
        1
      ),
      _c("div", { staticClass: "paging" }, [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 50],
                "page-size": _vm.pageSize,
                layout: "sizes,total, prev, pager, next",
                total: this.allpage
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPage = $event
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }